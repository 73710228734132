var render = function render(){var _vm=this,_c=_vm._self._c;return _c('HXContentCard',{attrs:{"title":"Requerimientos del proceso de selección","defaultToBack":true}},[_c('div',{staticClass:"row px-4"},[_vm._l((_vm.requerimientos),function(etapa,idx){return _c('div',{key:etapa.id,staticClass:"col-12 my-3"},[_c('div',{staticClass:"mb-3"},[_c('h3',{staticClass:"title"},[_c('strong',[_vm._v(_vm._s(etapa.nombre))])]),(_vm.isDocumentExist(etapa.nombre))?_c('div',[_c('p',{staticClass:"subTitle"},[_vm._v(" Ya existe un documento cargado... "),_c('a',{attrs:{"target":"_blank","href":_vm.getByName(etapa.nombre).urlImg ?? ''}},[_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"fas fa-external-link-alt",attrs:{"title":"Ver documento"}})])]),_c('p',[_vm._v(" El/La "+_vm._s(etapa.nombre)+" está "),_c('b-badge',{attrs:{"variant":_vm.getByName(etapa.nombre).estado.estado == 'Aprobado'
                  ? 'success'
                  : _vm.getByName(etapa.nombre).estado.estado == 'Rechazado'
                  ? 'danger'
                  : 'warning'}},[_c('i',{class:`fas ${
                  _vm.getByName(etapa.nombre).estado.estado == 'Aprobado'
                    ? 'fa-check-circle'
                    : _vm.getByName(etapa.nombre).estado.estado == 'Rechazado'
                    ? 'fa-times-circle'
                    : 'fa-clock'
                }`}),_vm._v(" "+_vm._s(_vm.getByName(etapa.nombre).estado.estado)+" ")])],1)]):_vm._e(),_c('BVForm',{attrs:{"schema":_vm.schemasEvalucion[idx],"injectData":{
            vacanteId: _vm.$route.params.vacanteId,
            postulacionId: _vm.$route.params.postulacionId,
            nombreIdEtapas: etapa.nombre,
            etapaTipoId: etapa.tipo.id,
          },"callBackSubmit":_vm.postEstado}})],1),_c('hr')])}),_c('div',{staticClass:"col-12 my-2"},[_c('BVForm',{attrs:{"schema":_vm.schemaEvaluacionSeleccion,"callBackSubmit":_vm.EvaluarProcesoDeSeleccion,"disabled":!_vm.isTodoEvaluado}})],1)],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }