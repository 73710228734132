<script>
import BVForm from "@/components/BVForm/BVForm.vue";
import Swal from "sweetalert2";

export default {
  components: {
    BVForm,
  },
  name: "CargarDocumentosDeSeleccion",
  data() {
    return {
      etapas: [],
      documentosExistentes: [],
      schemasEvalucion: [],
      estados: [],
      schemaEvaluacionSeleccion: [
        {
          name: "SeleccionEvaluacionId",
          label: "Evaluación final del proceso de selección",
          type: "select",
          options: [],
          value: null,
        },
      ],
    };
  },
  async mounted() {
    this.$isLoading(true);
    await this.getEtapas();
    await this.getEstados();
    await this.getDocumentos();

    this.$isLoading(false);
  },
  methods: {
    async nextStepOnSuccess() {
      await this.getDocumentos();
    },
    async getEtapas() {
      const response = await this.$store.getters.fetchGet({
        path: `ReclutamientoYSeleccion/Seleccion/etapas/${this.$route.params.vacanteId}`,
      });
      const etapas = await response.json();
      this.etapas = etapas;
      console.log("Estapas", etapas);
    },
    async getEstados() {
      const response = await this.$store.getters.fetchGet({
        path: "ReclutamientoYSeleccion/Seleccion/estados",
      });
      this.estados = await response.json();
      this.schemaEvaluacionSeleccion[0].options = JSON.parse(
        JSON.stringify(this.estados)
      );

      console.log(this.estados);
    },
    async getDocumentos() {
      const response = await this.$store.getters.fetchGet({
        path: `ReclutamientoYSeleccion/Seleccion/documentos/vacante/${this.$route.params.vacanteId}/postulacion/${this.$route.params.postulacionId}`,
      });
      this.documentosExistentes = await response.json();

      this.etapas.forEach((etapa) => {
        const doc = this.getByName(etapa.nombre);
        console.log("doc", doc);

        let newSchema = [
          {
            label: etapa.nombre,
            value: doc ? doc.estado.id : null,
            name: "EstadoId",
            type: "select",
            options: JSON.parse(JSON.stringify(this.estados)),
            validations: "required",
          },
        ];

        if (etapa.esCuantificable != 0) {
          newSchema.push({
            label: "Puntaje",
            name: "puntaje",
            value: doc ? doc.puntaje : null,
            type: "number",
            validations: "required",
          });
        }

        if (etapa.tipo.jsString == null) {
          newSchema.push({
            label: "Observaciones",
            name: "descripcion",
            value: doc ? doc.descripcion : null,
            type: "text",
            validations: "",
          });
        }

        this.schemasEvalucion.push(newSchema);
      });
    },
    documentosInjection(requerimiento) {
      return {
        vacanteId: this.$route.params.vacanteId,
        postulacionId: this.$route.params.postulacionId,
        etapaTipoId: requerimiento.tipo.id,
        nombreIdEtapas: requerimiento.nombre,
      };
    },
    isDocumentExist(nombre) {
      return (
        this.documentosExistentes.filter(
          (element) => element.nombreIdEtapas == nombre
        ).length > 0
      );
    },
    getByName(nombre) {
      return this.documentosExistentes.find(
        (element) => element.nombreIdEtapas == nombre
      );
    },
    isEvaluated(nombre) {
      if (this.getByName(nombre)) {
        return this.getByName(nombre).estado.estado != "Pendiente";
      } else {
        return false;
      }
    },
    async postEstado(self) {
      const data = { ...self.data };
      console.log(data);
      self.loader = true;

      const responseGet = await this.$store.getters.fetchGet({
        path: `ReclutamientoYSeleccion/Seleccion/documentos/vacante/${data.vacanteId}/postulacion/${data.postulacionId}/nombreEtapa/${data.nombreIdEtapas}`,
      });
      const resultGet = await responseGet.json();

      // console.log(responseGet.ok);
      // console.log(resultGet);

      let responseEstado;

      if (resultGet.length) {
        responseEstado = await this.$store.getters.fetchPut({
          path: `ReclutamientoYSeleccion/Seleccion/documentos/estado/${resultGet[0].id}`,
          data,
        });
      } else {
        responseEstado = await this.$store.getters.fetchPost({
          path: `ReclutamientoYSeleccion/Seleccion/documentos/admin`,
          data,
        });
      }

      if (responseEstado.ok) {
        Swal.fire({
          icon: "success",
          title: "Evaluado",
          showConfirmButton: true,
        });
      } else {
        Swal.fire({
          icon: "Error",
          title: "Inténtalo nuevamente",
          showConfirmButton: true,
        });
      }

      this.getDocumentos();
      self.loader = false;
    },
    EvaluarProcesoDeSeleccion(self) {
      const data = { ...self.data };

      self.loader = true;

      this.$store.getters
        .fetchPut({
          path: `Postulacion/ProcesoDeSeleccion/${this.$route.params.postulacionId}`,
          data,
        })
        .then((response) => {
          if (response.ok) {
            Swal.fire({
              icon: "success",
              title: "Proceso de selección finalizado",
              showConfirmButton: true,
            });
          } else {
            Swal.fire({
              icon: "Error",
              title: "Inténtalo nuevamente",
              showConfirmButton: true,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          self.loader = false;
        });
    },
  },
  computed: {
    requerimientos() {
      return this.etapas.sort((a, b) => {
        if (a.tipo.jsString == null) return 1;
        if (b.tipo.jsString == null) return -1;
        return a.tipo.jsString.localeCompare(b.tipo.jsString);
      });
    },
    isTodoEvaluado() {
      let documentList = this.documentosExistentes.map((item) => ({
        nombre: item.nombreIdEtapas,
        estado: item.estado.estado,
      }));
      let etapasList = this.etapas.map((item) => item.nombre);
      return etapasList.every((item) =>
        documentList.some(
          (element) => element.nombre == item && element.estado != "Pendiente"
        )
      );
    },
  },
};
</script>
<template>
  <HXContentCard
    title="Requerimientos del proceso de selección"
    :defaultToBack="true"
  >
    <div class="row px-4">
      <div
        v-for="(etapa, idx) in requerimientos"
        :key="etapa.id"
        class="col-12 my-3"
      >
        <!-- <div class="mb-3" v-if="etapa.tipo.jsString != null"> -->
        <div class="mb-3">
          <h3 class="title">
            <strong>{{ etapa.nombre }}</strong>
          </h3>
          <div v-if="isDocumentExist(etapa.nombre)">
            <p class="subTitle">
              Ya existe un documento cargado...
              <a target="_blank" :href="getByName(etapa.nombre).urlImg ?? ''">
                <i
                  v-b-tooltip.hover
                  title="Ver documento"
                  class="fas fa-external-link-alt"
                ></i>
              </a>
            </p>

            <p>
              El/La {{ etapa.nombre }} está
              <b-badge
                :variant="
                  getByName(etapa.nombre).estado.estado == 'Aprobado'
                    ? 'success'
                    : getByName(etapa.nombre).estado.estado == 'Rechazado'
                    ? 'danger'
                    : 'warning'
                "
              >
                <i
                  :class="`fas ${
                    getByName(etapa.nombre).estado.estado == 'Aprobado'
                      ? 'fa-check-circle'
                      : getByName(etapa.nombre).estado.estado == 'Rechazado'
                      ? 'fa-times-circle'
                      : 'fa-clock'
                  }`"
                ></i>
                {{ getByName(etapa.nombre).estado.estado }}
              </b-badge>
            </p>
          </div>
          <BVForm
            :schema="schemasEvalucion[idx]"
            :injectData="{
              vacanteId: $route.params.vacanteId,
              postulacionId: $route.params.postulacionId,
              nombreIdEtapas: etapa.nombre,
              etapaTipoId: etapa.tipo.id,
            }"
            :callBackSubmit="postEstado"
          ></BVForm>
        </div>
        <hr />
      </div>
      <div class="col-12 my-2">
        <BVForm
          :schema="schemaEvaluacionSeleccion"
          :callBackSubmit="EvaluarProcesoDeSeleccion"
          :disabled="!isTodoEvaluado"
        ></BVForm>
      </div>
    </div>
  </HXContentCard>
</template>
<style scoped>
.title {
  font-size: 1.4rem;
  text-transform: capitalize;
}

.subTitle {
  font-size: 1rem;
}
</style>
